.letter{
    font-size: 1.5rem;
    color:white;
    min-width: 1.5rem;
    min-height: 1.5rem;
    text-transform: capitalize;
    font-weight: bold;
    text-align: center;
    border-bottom: 0.125rem solid white;
    background: var(--color-primary);
    line-height: normal;
}