.game-window {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  animation: showWindow 1s;
  user-select: none;
}

.letters-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.word-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.word-title {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.1rem.5rem;
  text-align: center;
  background: var(--color-secondary);
  letter-spacing: .1rem;
}

.figute-keypad-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
}

.figure-container {
  width: 20rem;
}

.notifications-and-keypad-container {
  /*width: 34rem;*/
  max-width: 34rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0rem 0.25rem 0.375rem 0rem #0e0e0e;
}

.notifications-container {
  width: 100%;
  text-align: center;
  font-size: 2rem;
}

.keypad-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  justify-content: center;
  background: gray;
  padding: 1rem;
}

@media screen and (max-width: 425px) {
  .game-window {
    gap: 2rem;
  }

  .figure-container {
    width: 40%;
    height: 30%;
    height: auto;
  }

  .notifications-and-keypad-container {
    width: 100%;
  }
}
