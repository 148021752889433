.sw-notification-container{
    color: white;

    font-weight: bold;
    position: fixed;
    z-index: 9;
    top:.2rem;
    padding: 2rem 3rem ;
    text-align: center;
    background: rgba(218, 11, 11, 0.535);
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}