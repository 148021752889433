.settings-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    border: 2px solid white;
    padding: 2rem 3rem;
    background: rgba( 255, 255, 255, 0.35 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    position: relative;
    animation: showWindow .7s;
    z-index: 9;
}

.settings-label{
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    flex-direction: column;
    color: white;
    gap:0.5rem;
    font-size: 1.7rem;
}

.settings-input{
    outline: none;
    padding: 7px 0 5px 6px;
    /* border-radius: 0.6rem; */
    border: none;
    background: transparent;
    border-bottom: 2px solid white;
    font-size: 1.5rem;
    max-width: 20rem;
    color:white;
}
.settings-input::placeholder{
    color:rgb(219, 219, 219);
}
.settings-input.clue{
    width: 4rem;
}


.settings-button{
    padding: 0.8rem 1rem;
    font-size: 1.5rem;
    width: 60%;
    margin: 0 auto;
    outline: none;
    -webkit-user-select: none;
    user-select: none;
    color: white;
    background: rgb(255 255 255 / 10%);
    box-shadow: 1px 4px 8px 0px rgba( 31, 38, 135, 0.37 );
    /* backdrop-filter: blur( 3.5px ); */
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.settings-figure-container{
    width: 50%;
    position: absolute;
    left: 3px;
    z-index: 1;
}

@media screen and (max-width:466px){
  
}