svg{
    width: 100%;
    height: 100%;
}

.path-ahorcado{
    position: relative;
    animation:showPart 2s, movePart 1s infinite alternate ease-in;
}

