.modal-container{
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur( 3.5px );
    -webkit-backdrop-filter: blur( 3.5px );
    border-radius: 10px;
    text-align: center;
    animation: showModal 1s;
    color: white;
}

.modal-title{
    font-size: 2.3rem;
}
.modal-text{
    font-size: 1.4rem;
}

.modal-button{
 padding:1rem 2rem;
 color: white;
 background: black;
 cursor:pointer;
 user-select: none;
 font-size: 1.4rem;
}

.modal-container--green{
    background: rgba(11, 218, 11, 0.535);
}

.modal-container--red{
    background: rgba(218, 11, 11, 0.535);
}