.key{
    padding: .3rem;
    border: 0.0625rem solid white;
    color: white;  
    cursor: pointer;
    border-radius: .3rem;
    box-shadow: 0rem 0.25rem 0rem 0rem #0e0e0e;
    user-select: none;
    background-color: var(--color-secondary);
    font-size: 2rem;
    min-width: 2.5rem;
}
.key-dissabled{
    background-color: red;
}
.key:hover{
    transform: scale(1.1,1.1);
}
.key:focus{
    box-shadow: inset 0rem 0rem 0.1875rem 0rem #0e0e0e;
    border: none;
}