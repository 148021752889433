*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --color-primary: #953d00cc;
  --color-secondary: #41d506;
}

body{
  background-color: black;
}

.App{
  position: relative;
  background-color: var(--color-secondary);
  background-image: linear-gradient(180deg, var(--color-primary) 0%, var(--color-secondary) 100%);
  padding: 2rem 1rem;
}

.window-title{
  color: white;
  font-size: 2.5rem;
  text-align: center;
  
}

.window-container{
    display: flex;
    width: 80%;
    min-height: 18.75rem;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 4rem);
    margin: 0 auto;
}

@media screen and (max-width:425px){

  html{
    font-size: 10px;
  }
  .window-container{
    width: 90%;
    padding: 0;
  }
  
}