.notification-text{
    background-color: black;
    color:white;
    padding: 1rem;
}

.black{
   
}

.red{
    color: red;
}

.green{
    color: greenyellow;
}